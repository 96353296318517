<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" rounded class="mr-2" dark v-bind="attrs" v-on="on">
        Add to Playlist
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5"> Add to Playlist </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  label="Playlist ID"
                  outlined
                  v-model="playlistId"
                ></v-text-field>
                <v-text-field
                  label="List Index"
                  outlined
                  v-model="listIndex"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text :loading="loading" @click="loopList"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  props: {
    plId: String,
  },
  data: () => ({
    dialog: false,
    playlistId: null,
    listIndex: null,
    loading: false,
    arrayReversed: [],
    arrayLoopIndex: 0,
  }),
  methods: {
    async loopList() {
      this.loading = true;
      const arrayReversed = this.videos[this.listIndex].videos.reverse();
      this.arrayReversed = arrayReversed;
      this.addVideoToPlaylist(
        0,
        this.playlistId,
        this.arrayReversed[0].videoId,
        true
      );
    },
    async addVideoToPlaylist(position, playlistId, videoId, loop) {
      try {
        let url = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&key=AIzaSyDieTcpz7wc8EV1Ths1bfZvFnp4mD0pXMY`;

        const response = await axios.post(
          url,
          {
            snippet: {
              playlistId: playlistId,
              position: position,
              resourceId: {
                kind: "youtube#video",
                videoId: videoId,
              },
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.oauthToken.access_token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        console.log("response", response);
        if (loop) {
          this.arrayLoopIndex++;
          if (this.arrayLoopIndex < this.arrayReversed.length) {
            this.addVideoToPlaylist(
              this.arrayLoopIndex,
              this.playlistId,
              this.arrayReversed[this.arrayLoopIndex].videoId,
              true
            );
          } else {
            this.loading = false;
            this.dialog = false;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    videos() {
      return this.$store.state.youtubeResponses;
    },
  },
  watch: {
    plId() {
      this.playlistId = this.plId;
      console.log("playlistIdLast", this.plId, this.playlistId);
    },
  },
};
</script>
