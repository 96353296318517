<template>
  <div id="app">
    <v-container>
      <v-row>
        <v-col class="d-flex">
          <v-spacer></v-spacer>
          <v-btn @click="handleAuthentication" text class="mr-2" rounded>
            <span class="mr-2">Authenticate</span>
            <v-icon>mdi-open-in-new</v-icon>
          </v-btn>
          <CreatePlaylistDialog @playlist-created="handlePlaylistCreated" />
          <GetUploadsIdDialog />
          <v-btn
            rounded
            color="success"
            @click.stop="handleGetVideos"
            :loading="loading"
            >Get Videos</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <v-spacer></v-spacer>
              <AddToPlaylistDialog :plId="latestPlaylistId" />
              <v-btn rounded color="error" class="mr-2" @click.stop="clearList"
                >Clear videos list</v-btn
              >
            </v-card-title>
            <v-card-text>
              <code>
                {{ videos }}
              </code>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import CreatePlaylistDialog from "../components/CreatePlaylistDialog";
import AddToPlaylistDialog from "../components/AddToPlaylistDialog";
import GetUploadsIdDialog from "../components/GetUploadsIdDialog";

export default {
  name: "App",
  components: {
    CreatePlaylistDialog,
    AddToPlaylistDialog,
    GetUploadsIdDialog,
  },
  data: () => ({
    nextToken: null,
    counter: 0,
    limit: 11,
    loading: false,
    form: {
      position: null,
      listIndex: null,
      videoIndex: null,
    },
    playlistName: null,
    listIndex: null,
    playlistId: null,
    arrayReversed: [],
    arrayLoopIndex: 0,
    latestPlaylistId: null,
  }),
  computed: {
    videos() {
      return this.$store.state.youtubeResponses;
    },
  },
  methods: {
    handlePlaylistCreated(id) {
      this.latestPlaylistId = id;
      console.log("latestPlaylistId", this.latestPlaylistId);
    },
    async loopList() {
      const arrayReversed = this.videos[this.listIndex].videos.reverse();
      this.arrayReversed = arrayReversed;
      this.addVideoToPlaylist(
        0,
        this.playlistId,
        this.arrayReversed[0].videoId,
        true
      );
    },

    clearList() {
      this.$store.commit("CLEAR_LIST");
      console.log("video-list", this.$store.state.youtubeResponses);
    },
    handleGetVideos() {
      this.getVideos();
    },
    handleUpload() {
      const video =
        this.videos[this.form.listIndex].videos[this.form.videoIndex];
      console.log("video", video);
      // this.addVideoToPlaylist(
      //   this.form.position,
      //   "PLGLf9WQGD5ZrZgb4pwbgvfTHeCuROZIAP",
      //   video.videoId
      // );
    },
    async getVideos(nextToken) {
      this.loading = true;
      try {
        let url = `https://www.googleapis.com/youtube/v3/playlistItems?playlistId=UUVL-jnHcCUYJHU9qNUr1JkA&key=AIzaSyDieTcpz7wc8EV1Ths1bfZvFnp4mD0pXMY&part=snippet&maxResults=50`;
        if (nextToken) {
          url += `&pageToken=${nextToken}`;
        }
        const response = await axios.get(url);
        this.nextToken = response.data.nextPageToken;
        const items = response.data.items;
        let videos = [];
        items.forEach((item) => {
          videos.push({
            position: item.snippet.position,
            title: item.snippet.title,
            publishedAt: item.snippet.publishedAt,
            videoId: item.snippet.resourceId.videoId,
          });
        });
        this.$store.commit("ADD_RESPONSE", { index: this.counter, videos });
        this.counter++;
        if (this.counter < this.limit) {
          this.getVideos(this.nextToken);
        }

        console.log("response", response);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleAuthentication() {
      // Google's OAuth 2.0 endpoint for requesting an access token
      var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      var form = document.createElement("form");
      form.setAttribute("method", "GET"); // Send as a GET request.
      form.setAttribute("action", oauth2Endpoint);

      // Parameters to pass to OAuth 2.0 endpoint.
      var params = {
        client_id:
          "81096989106-kf0rn2doahnljdgj41hvh02tsdur0qvd.apps.googleusercontent.com",
        redirect_uri: "https://yt.yiosh.eu/oauth2callback",
        response_type: "token",
        scope: "https://www.googleapis.com/auth/youtube.force-ssl",
        include_granted_scopes: "true",
        state: "pass-through value",
      };

      // Add form parameters as hidden input values.
      for (var p in params) {
        var input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", p);
        input.setAttribute("value", params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
    },
  },
  created() {
    console.log("video-list", this.$store.state.youtubeResponses);
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
