<template>
  <div class="about"></div>
</template>

<script>
export default {
  beforeMount() {
    const fragmentString = location.hash.substring(1);

    // Parse query string to see if page request is coming from OAuth 2.0 server.
    let params = {};
    var regex = /([^&=]+)=([^&]*)/g,
      m;
    while ((m = regex.exec(fragmentString))) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }
    console.log("params", params);
    this.$store.commit("SET_TOKEN", params);
    this.$router.push("/");
  },
};
</script>
