<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" rounded class="mr-2" dark v-bind="attrs" v-on="on">
        Create Playlist
      </v-btn>
    </template>

    <v-card>
      <v-card-title> Create Playlist </v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  label="Name"
                  outlined
                  v-model="name"
                ></v-text-field>

                <p class="text-center mt-4">{{ playlistId }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="!name"
          :loading="loading"
          @click="handleCreatePlaylist"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    playlistId: null,
    name: null,
    loading: false,
  }),
  methods: {
    async handleCreatePlaylist() {
      this.loading = true;
      try {
        let url = `https://youtube.googleapis.com/youtube/v3/playlists?part=snippet&key=AIzaSyDieTcpz7wc8EV1Ths1bfZvFnp4mD0pXMY`;

        const response = await axios.post(
          url,
          {
            snippet: {
              title: this.name,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.oauthToken.access_token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        console.log("response", response);
        this.playlistId = response.data.id;
        this.$emit("playlist-created", this.playlistId);
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
