<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="success" rounded class="mr-2" dark v-bind="attrs" v-on="on">
        Get Uploads Id
      </v-btn>
    </template>

    <v-card>
      <v-card-title>Get Channel's Uploads Id</v-card-title>

      <v-card-text>
        <v-form>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  label="Channel Id"
                  outlined
                  v-model="channelId"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <code class="mt-2">{{ code }}</code>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          :disabled="!channelId"
          :loading="loading"
          @click="getChannelUploadsId"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    dialog: false,
    channelId: null,
    loading: false,
    code: null,
  }),
  methods: {
    async getChannelUploadsId() {
      this.loading = true;
      try {
        let url = `https://www.googleapis.com/youtube/v3/channels?id=${this.channelId}&key=AIzaSyDieTcpz7wc8EV1Ths1bfZvFnp4mD0pXMY&part=contentDetails`;

        const response = await axios.get(url);

        console.log("getChannelUploadsId", response);
        this.code = response.data;
      } catch (error) {
        alert(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
